import React from "react"
import { graphql } from "gatsby"
import { ModalRoutingContext, Link } from "gatsby-plugin-modal-routing"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import Close from "../images/components/close.svg"
import Previous from "../images/components/previous.svg"
import Next from "../images/components/next.svg"
import dayjs from "dayjs"

export const WorksWorkTemplate = ({
  work,
  modal,
  closeTo,
  previous,
  next,
  image
}) => {
  const isFluid = typeof image == "object"

  const content = (
    <>
      <div className="works-work__image">
        {image ? (
          <img
            alt={work.title}
            src={isFluid ? image.childImageSharp.fluid.src : image}
            srcSet={isFluid ? image.childImageSharp.fluid.srcSet : ""}
          />
        ) : null}
      </div>
      <div className="works-work__content">
        <div className="works-work__title">
          <h3>{work.title}</h3>
          <h4>{dayjs(work.date).format("YYYY")}</h4>
        </div>
        <div className="works-work__description">
          <ReactMarkdown
            source={work.description}
            renderers={{
              link: props => (
                <a href={props.href} target="_blank" rel="noopener noreferrer">
                  {props.children}
                </a>
              )
            }}
          />
          <ReactMarkdown
            source={work.description_secondary}
            renderers={{
              link: props => (
                <a href={props.href} target="_blank" rel="noopener noreferrer">
                  {props.children}
                </a>
              )
            }}
          />
        </div>
      </div>
    </>
  )

  const asModal = content => (
    <>
      <Link to={closeTo} className="modal__button modal__button--close">
        <Close />
      </Link>
      <main className="modal__main">
        <div className="works-work">{content}</div>
      </main>
      <div className="modal__page modal__page--previous">
        {previous ? (
          <Link to={previous} asModal className="modal__button">
            <Previous />
          </Link>
        ) : null}
      </div>
      <div className="modal__page modal__page--next">
        {next ? (
          <Link to={next} asModal className="modal__button">
            <Next />
          </Link>
        ) : null}
      </div>
    </>
  )

  return (
    <>
      {modal ? asModal(content) : <div className="works-work">{content}</div>}
    </>
  )
}

const WorksWork = ({ data, pageContext }) => {
  const { markdownRemark: work } = data
  const { previous, next } = pageContext

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <Layout modal={modal} modalTitle={work.frontmatter.title}>
          <WorksWorkTemplate
            work={work.frontmatter}
            modal={modal}
            closeTo={closeTo}
            previous={previous ? previous.node.fields.slug : null}
            next={next ? next.node.fields.slug : null}
            image={work.frontmatter.image}
          />
        </Layout>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export default WorksWork

export const pageQuery = graphql`
  query WorkByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        description_secondary
        templateKey
        date
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
